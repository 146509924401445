.hero-section {
  background-image: url(../../img/home-img/hero-background-img.png);
  background-size: cover;
  height: auto;
}
.hero-section {
  padding: 0 100px 0 100px;
}

.choose .heading {
  text-align: start;
}

.choose .heading h2 {
  font-size: 40px;
}

.choose .heading hr {
  display: inline-block;
  width: 70px;
  color: darkblue;
  height: 5px;
}

.choose .main-card {
  margin-bottom: 50px;
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.choose .card-img img {
  padding: 0px 50px 50px;
}

.choose .choose-content p {
  /* line-height: 30px; */

  font-size: 17px;
  font-weight: 500;
}

/* service start  */
.index-service p {
  color: #262524;
}
.index-service h3 {
  font-size: 30px;
  font-family: "Anton SC", sans-serif !important ;
  font-weight: 400;
  font-style: normal;
  color: #262524;
  letter-spacing: 2px;
  text-transform: capitalize;
}
/* service end */



.slide-img {
  margin-top: 20px;
}

.slide-img .main-slide img {
  height: 80px;
  width: auto;
  margin: auto;
}

.clients {
  text-align: center;
  margin-top: 50px;
}






.slide-img-q {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}
.slide-img-q img {
  border-radius: 60px;
  padding: 15px;
}
.client-container .btn1 {
  text-align: center;
  margin-top: 100px;
}

/* our tech toolkit  section start */

.toolkit-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 120px;
  padding-right: 120px;
}

.toolkit-container .item {
  flex: 1 21%;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  background-color: #135c8af4;
}
.toolkit-container .item h5 {
  font-weight: bolder;
  font-size: 20px;
  color: rgb(234, 234, 255);
}
.toolkit-container .item p {
  color: white;
  font-size: 15px;
}
.toolkit-container .item svg {
  color: #efedfd;
}
.toolkit-container .heading {
  text-align: center;
}

/* @media (min-width: 1540px) {
  .hero-section .hero-heading h1 {
    font-size: 90px;
    font-family: "Anton SC", sans-serif !important ;
    font-weight: 400;
    font-style: normal;
    color: #262524;
    letter-spacing: 2px;
  }
  .hero-section .hero-sub p {
    font-size: 40px;
  }
  .hero-content .top h5 {
    color: gray;
    font-weight: bolder;
    font-size: 30px;
  }
} */



/* ************************************************ */

/* Client section start */

/* ***************************************** */


@keyframes slide {
  from {
    transform: translateX(1000px);
  }
  to {
    transform: translateX(-150%);
  }
}
.logos {
  overflow: hidden;
  padding: 10px 0;
  background: rgb(246, 244, 244);
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}
.logos:before {
  left: 0;
 
}
.logos:after {
  right: 0;
 
}
.logos:hover .logos-slide {
  animation-play-state: paused;
}
.logos-slide {
 
  display: inline-block;
  animation: 10s slide infinite linear;
}
.logos-slide li{
  list-style: none;
}
.logos-slide span{
  margin-left: -30px;
 
  
  font-family: "Chango", sans-serif;
  font-weight: 600;
   opacity: 0.8;
   
    font-style: normal;
 
  
  font-size: 26px;
  color: black;
  text-shadow: 1px 5px 3px rgba(217, 216, 242, 0.81);
}
.logos-slide p {
  font-size: 20px;
  font-weight: 500;
}
.logos-slide p{
  color: #262524;
}
.logos-slide img {
  height: 80px;
  margin: 0 40px;
}

/* ************************************************ */

/* Client section end  */

/* ***************************************** */
  


/*  responsive Web Design start */

/* 2xl */
@media (max-width: 2560px) {
  .hero-section .hero-heading h1 {
    font-size: 60px;
    font-family: "Anton SC", sans-serif !important ;
    font-weight: 400;
    font-style: normal;
    color: #262524;
    letter-spacing: 2px;
  }
}
/* xl */
@media (max-width: 1440px) {
  .hero-section .hero-heading h1 {
    font-size: 40px;
    font-family: "Anton SC", sans-serif !important ;
    font-weight: 400;
    font-style: normal;
    color: #262524;
    letter-spacing: 2px;
  }
}

/* lg */
@media (max-width: 1024px) {
  .hero-section .hero-heading h1 {
    font-family: "Anton SC", sans-serif !important ;
    font-weight: 400;
    font-style: normal;
    color: #262524;
    letter-spacing: 2px;

    font-size: 30px;
  }
}

/* md */
@media (max-width: 768px) {
  .hero-section {
    height: auto;
  }
  @keyframes slide {
    from {
      transform: translateX(650px);
    }
    to {
      transform: translateX(-90%);
    }
  }
  .hero-section .hero-img {
    display: none;
  }
  .hero-section {
    padding: 20px 40px 10px 40px;
  }

  .toolkit-container .item {
    flex: 1 1 30%;
  }
  .toolkit-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .toolkit-container .item {
    flex: 1 1 50%;
  }
}

/* sm */


@media (max-width: 475px) {
  .choose .choose-content p {
    line-height: 30px;
    /* color: black; */
    /* font-size: 22px; */
    font-weight: 500;
    margin-left: 20px;
  }
 
  .choose .heading h2 {
    margin-left: 20px;
    font-size: 30px;
  }
  .choose .heading hr {
    margin-left: 20px;
  }
  .toolkit-container .item {
    flex: 1 1 100%;
  }
  .toolkit-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  @keyframes slide {
    from {
      transform: translateX(500px);
    }
    to {
      transform: translateX(-70%);
    }
  }
}
